.service-details-wrapper {
  background: none;
  // height: 150px;
  width: 100%;
  height: 100vh;
  padding: 25px;
  .header-section {
    .backIcon {
      font-size: 30px;
      color: #224691;
      position: absolute;
      left: 19px;
      cursor: pointer;
      z-index: 1007;
      // background-color: #efefef;
      border-radius: 10px;
    }
    .app-header {
      text-align: center;
      color: #2546b1;
      font-size: 17pt;
      font-weight: bold;
    }
  }
  .title {
    padding: 0px;
    height: 100px;
  }

  .title .welcome {
    font-weight: bold;
    font-size: 13pt;
    color: black;
    height: 70%;
    width: 100%;
    float: left;
    padding: 10px;
    background: white;
    border-radius: 2px;
    border: 1px solid #e5e2e2;

    .user-box {
      float: left;
      font-size: 20pt;
      height: 100%;
      width: 60pt;
      text-align: center;
      padding-top: 2px;

      img {
        height: 50px;
        border-radius: 35px;
        width: 50px;
      }
    }
  }

  .title .request-header {
    float: left;
    height: 30px;
    width: 100%;
    text-align: center;
    font-weight: bold;
  }

  .issue-description-wrapper {
    font-family: arial;
    height: 250px;
    height: 270px;
    border-bottom: 1px solid #eeecec;

    .issue-container {
      width: 100%;
      margin: 0;

      .issue-description {
        width: 100%;
        text-align: left;
        padding-top: 10px;
        font-weight: bold;
        font-size: 13pt;
        float: left;
        width: 100%;
        height: 40px;
      }

      .part-specification {
        width: 100%;
        float: left;
        text-align: left;
        padding-top: 5px;
      }

      .issue-time-status {
        width: 100%;
        float: left;
        padding-top: 20px;
        font-size: 11pt;

        .issue-timestamp {
          width: 40%;
          float: left;
          text-align: left;
        }

        .issue-status-description-details {
          width: 58%;
          float: left;
          text-align: right;
          color: red;
        }
      }

      .detailed-description {
        height: 140px;
        text-align: left;
        width: 100%;
        float: left;
        // padding-top: 15px;
        padding-bottom: 5px;

        .description-header {
          font-weight: bold;
        }

        .description-details {
          font-size: 10pt;
        }

        .description-images-complete {
          width: 100%;
          height: 50px;
          img {
            padding-right: 20px;
          }
        }
      }
    }
  }

  .workflow-wrapper {
    font-family: arial;
    float: left;
    width: 100%;
    margin-bottom: 25px;
    .workflow-header {
      padding-top: 10px;
      height: 30px;
      text-align: left;
      font-size: 13pt;
      font-weight: bold;
      padding-left:10px;
    }

    .workflow-steps {
      height: 88px;
      width: 100%;
      /* border: 1px solid grey; */

      .step-summary {
        width: 100%;
        height: 100%;

        .worflow-diagram {
          height: 60px;
          float: left;
          width: 7%;
          padding-top: 10px;

          .workflow-round {
            height: 20px;
            width: 20px;
            border: 1px solid black;
            border-radius: 20px;
          }

          .workflow-arrow {
            height: 43px;
            margin-left: 11px;
            margin-top: 7px;
            width: 100%;
            border-left: 1px solid black;
          }
        }

        .step-details {
          width: 92%;
          height: 30px;
          padding-top: 10px;
          float: left;

          .step-description {
            width: 51%;
            float: left;
            text-align: left;
            font-size: 10pt;
          }

          .step-timestamp {
            width: 49%;
            float: left;
            text-align: right;
            font-size: 10pt;
            color: #938e8e;
          }
        }

        .step-approval {
          width: 90%;
          height: 30px;
          font-size: 10pt;
          float: left;
          padding-top: 10px;

          .step-approver-details {
            width: 60%;
            float: left;
            text-align: left;
          }

          .step-status {
            width: 40%;
            float: left;
            text-align: right;

            .pending-button {
              background-color: #ff7c52;
              color: white;
              border: none;
            }
          }

          .pending {
            color: red;
          }
        }
      }
      .active {
        margin-bottom: 10px;
        margin-top: 10px;
        padding: 1px 5px;
        box-shadow: 1px;
        box-shadow: 1px 3px 7px grey;
        font-weight: bold;
      }
    }
  }
  .pending-steps-wrapper {
    width: 100%;
    font-family: arial;
    float: left;

    .task-header {
      font-size: 13pt;
      font-weight: bold;
      text-align: left;
      width: 100%;
      height: 30px;
      color: rgba(128, 128, 128, 0.774);
    }

    .task-list-body {
      height: calc(100% - 10px);
      width: 100%;
      color: rgba(128, 128, 128, 0.774);

      .task-list-details {
        float: left;
        width: 100%;
        padding-bottom: 10px;

        .workflow-round {
          height: 20px;
          width: 20px;
          border: 1px solid black;
          border-radius: 20px;
          float: left;
        }

        .task-name {
          width: 80%;
          float: left;
          text-align: left;
          padding-left: 20px;
        }
      }
    }
  }
}
.approve-modal {
  .modal-dialog {
    background: #0000004d !important;

    .modal-content {
      width: 96%;
      top: 32%;
      height: 200px;
      position: fixed;
      border-radius: 0px;

      .modal-header {
        padding: 1rem 1rem !important;
        font-weight: bold;
        color: #2546b1;
      }
      .modal-body {
      }
      .approve-deny-button-group {
        padding-top: 30px;
        width: 100%;
        text-align: center;

        .deny-button {
          padding: 10px 9px;
          margin-right: 10px;
          background: #ff7c52;
          border: transparent;
          border-radius: 2px;
          font-weight: lighter;
          font-family: arial;
        }

        .approve-button {
          padding: 10px 15px;
          border-radius: 2px;
          background: #2546B1;
          border: transparent;
          font-weight: lighter;
          font-family: arial;
        }
      }
    }
  }
}

.comment-modal {
  .modal-dialog {
    background: none !important;
    color: #2546B1;
    .modal-content {
      top: 32%;
      // margin-left: -8px;
      width:96%;
      height: 255px;
      position: fixed;
      border-radius: 0px;

      .modal-header {
        font-weight: bold;
      }

      .modal-body {
        padding-bottom: 0px;
      }

      .comment-button-group {
        width: 100%;
        text-align: center;
        .close-form {
          padding: 8px 25px;
          margin-right: 10px;
          color: black;
          background: white;
          border-radius: 2px;
          font-weight: lighter;
          font-family: arial;
        }

        .submit-approval {
          padding: 8px 22px;
          border-radius: 2px;
          font-weight: lighter;
          font-family: arial;
          background-color: #FF7C52;
          border:#FF7C52;
        }
      }
    }
  }
}
.estimate-modal {
  z-index: 10;
  .modal-dialog {
    background: none !important;
    // top: 67%;
    z-index: 10;
    .form-label {
      padding-top: 10px;
    }
    .field {
      z-index: 10;
      width: 100%;
    }
    .comment-text{
      text-align: left;
    }
    .modal-content {
      font-weight: bold;
      top: 25%;
      color: #2546B1;
      width:96%;
      height: 450px;
      position: fixed;
      border-radius: 0px;

      .modal-body {
        padding-bottom: 0px;
        height: 200px;
        width: 100%;
        float: left;
        text-align: left;
        .request-estimate-header {
          width: 100%;
          float: left;
          text-align: center;
          font-family: Arial, Helvetica, sans-serif;
          font-size: 12pt;
        }
      }
      .submit-approval{
        background-color: #FF7C52;
        border:#FF7C52;
      }
    }
  }
}
.comment-button-group {
  float: left;
  width: 100%;
  height: 70px;
  text-align: center;

  .close-form {
    padding: 8px 25px;
    margin-right: 10px;
    color: black;
    background: white;
    border-radius: 2px;
    font-weight: lighter;
    font-family: arial;
  }

  .submit-approval {
    padding: 8px 22px;
    border-radius: 2px;
    font-weight: lighter;
    font-family: arial;
  }
}

.p-calendar {
  width: 100%;
  background: transparent;

  .p-inputtext .p-component {
    background: transparent;
  }
  .p-inputtext:enabled {
    border-color: #e5e8eb;
  }
  .p-inputtext:enabled:hover {
    border-color: #e5e8eb;
  }
  .p-datepicker:not(.p-datepicker-inline) {
    background: #6b747c;
  }
}
