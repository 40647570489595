.App {
    text-align: center;
    background-color: #2546b1;
    background-image: url("../../assets/noun_Engine_2445350.svg");
    height: 100vh;
    background-position: bottom;
    background-repeat: no-repeat;
    background-position-x: left;

    .auth-text {
      color: #fff;
    }
  }
  .head {
    padding-top: 50px;
    width: fit-content;
    height: 50%;
    margin: 0 auto;
    .logo {
      float: left;
      margin-right: 7px;
      padding-top: 0px;
      width: 80pt;
    }
    .title {
      color: #fff;
      font-size: 30pt;
      text-align: left;
      display: inline-block;
    }
  }
  .body {
    .login-btn {
      height: 50px;
      background-color: #ff6302d1;
      border-radius: 25px;
      width: 200px;
      border: none;
      font-size: 13pt;
      font-weight: 500;
    }
    .login-btn:active {
      background-color: #ff6302d1 !important;
      border: none;
    }
  }

