@font-face {
  font-family: "Damen-Font";
  src: local("Damen-Font"), url(../../fonts/font.woff) format("woff");
}

.service-request-wrapper {
  height: 90vh;
  max-width: 480px;
  margin: 0 auto;
  font-family: Damen-font !important;
  overflow: hidden;
  .header-wrapper {
    width: 100%;
    padding: 15px;
    height: 130px;
    text-align: center;
    font-weight: bold;
    float: left;
    background: #80808021;
    .app-header {
      width: 100%;
      height: 70px;
      text-align: center;
      color: #2546b1;
      font-size: 17pt;
      padding-top: 20px;
      float: left;
    }
    .service-request-header {
      width: 56%;
      height: 30px;
      text-align: left;
      font-weight: bold;
      font-size: 13px;
      padding-top: 10px;
      float: left;
    }
  }
  .service-request-details-wrapper {
    float: left;
    width: 100%;
    height: 579px;
    .service-details-summary {
      width: 100%;
      height: 120px;
      border-bottom: 1px solid #80808069;
      .vessel-image {
        width: 24%;
        height: 100%;
        float: left;
        padding-top: 20px;
        // padding-left: 10px;
        .user-box {
          float: left;
          font-size: 20pt;
          height: 100%;
          width: 60pt;
          text-align: center;
          padding-top: 2px;

          img {
            height: 60px;
            border-radius: 35px;
            width: 60px;
          }
        }
      }
      .issue-details-wrapper {
        padding-top: 10px;
        padding-left: 10px;
        width: 76%;
        float: left;
        .vessel-details {
          width: 100%;
          float: left;
          height: 25px;
          font-size: 13pt;
          font-weight: bold;
        }
        .issue-description {
          float: left;
          height: 22px;
          width: 100%;
          font-size: 10pt;
          text-transform: capitalize;
          word-break: break-all;
        }

        .issue-timestamp {
          float: left;
          width: 46%;
        }
        .issue-status {
          float: left;
          width: 52%;
          text-align: right;
          color: red;
        }
        .in-progress {
          color: orangered;
        }
        .resolved {
          color: green;
        }
      }

      .assigned {
        padding-left: 0px;
      }

      .assigned-status {
        width: 47%;
      }

      .remote-access {
        width: 100%;
        height: 100%;
        font-weight: bold;
        .remote-access-customer,
        .remote-date,
        .remote-status {
          font-weight: normal;
          font-size: 10pt;
        }
      }
    }
  }
  .service-request-remote-access {
    float: right;
    padding: 5px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    left: -4px;
    margin: 0 auto !important;
    max-width: 480px !important;
    .request-access-button {
      float: right;
      padding: 10px;
      background-color: #2546b1;
      border: none;
      margin-top: 10px;
      margin-right: 10px;
      border-radius: 18px;
      .lock-icon {
        font-size: 1.5em;
        padding-bottom: 5px;
      }
    }

    .card {
      margin-left: -12px;
      float: left;
      width: 60%;
      zoom: 0.9;
    }

    .p-paginator .p-component {
      //width: 100%;
      float: left;
    }

    // .p-paginator .p-paginator-pages .p-paginator-page {
    //   min-width: 2.5rem;
    //   height: 2.5rem;
    // }

    // .p-paginator .p-paginator-first {
    //   display: none;
    // }

    // .p-paginator .p-paginator-prev,
    // .p-paginator .p-paginator-next {
    //   margin-left: -19px;
    // }

    // .p-paginator-next .p-paginator-element .p-link {
    //   margin-left: -11px;
    // }

    // .p-paginator-pages {
    //   margin-left: -14px;
    // }

    // .p-paginator-next .p-paginator-element {
    //   margin-left: -9px;
    // }
    // .p-paginator .p-paginator-last {
    //   display: none;
    // }
    .p-paginator .p-dropdown {
      margin-left: 0;
      height: 3rem;
      margin-right: -25px;
      width: 85px !important;
    }
    .p-dropdown-panel {
      left: 215px !important;
      width: 18% !important;
    }
  }
}

.access-form-modal {
  .modal-content {
    font-weight: bold;
    top: 7px;
    height: 98%;
    width: 96%;
    position: fixed;
    border-radius: 0px;
    border: transparent;
    max-width: 480px;
    margin: 0 auto !important;
    .modal-header {
      color: #2546b1;
    }

    .modal-body {
      padding-bottom: 0px;
      height: 200px;
      width: 100%;
      float: left;
      max-height: 95vh;
      overflow: auto;
      .request-estimate-header {
        width: 100%;
        float: left;
        text-align: center;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 12pt;
      }
      .work-report-title,
      .work-report-description,
      .select-title {
        color: #2546b1;
        text-align: left;
        float: left;
        width: 100%;
      }
      .vessel {
        width: 75%;
      }
    }
    .submit-approval {
      background-color: #ff7c52;
      border: #ff7c52;
    }
  }
}

.detail-modal {
  .modal-content {
    top: 36vh;
    height: 250px;
    .modal-header {
      color: #2546b1;
    }
    .modal-body {
      padding: 14px 13px;
      font-family: Arial;
    }
  }
}

.p-tabview .p-tabview-nav li {
  width: 50%;
  float: left;
  color: #2546b1;
  padding-left: 53px;
}

.p-tabview .p-tabview-nav li :focus,
.p-tabview .p-tabview-nav li :hover,
.p-tabview .p-tabview-nav li:active {
  outline: none;
  border: none;
  text-decoration: none;
  color: #2546b1;
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  width: 197px;
  left: 0px;
}

.p-tabview-title {
  font-family: Damen-font !important;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.2rem white !important;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: transparent;
  border-color: #2546b1;
  color: #2546b1;
}

.p-datepicker {
  z-index: 1056 !important;
  top: 30px;
}

.loader {
  z-index: 1056 !important;
}
.p-connected-overlay-enter-done {
  z-index: 1056 !important;
  // left: 25px !important;
  // width: 85% !important;
}

// .p-dropdown-panel {
//   left: 25px !important;
//   width: 85% !important;
// }

.p-dropdown {
  width: 100% !important;
  height: 41px !important;
}

.p-dropdown .p-dropdown-label.p-placeholder {
  margin-top: -4px !important;
  color: hsl(0, 0%, 20%) !important;
}
.p-dropdown .p-dropdown-label.p-inputtext {
  margin-top: -6px !important;
  color: hsl(0, 0%, 20%) !important;
}
.p-tabview-panels {
  overflow: auto !important;
  height: 75vh !important;
  width: 100% !important;
}

.p-tabview-panel {
  height: 88%;
  width: 100%;
}
.p-tabview .p-tabview-panels {
  padding: 0rem !important;
  padding-top: 0px !important;
}

.p-calendar .p-inputtext:enabled:enabled,
.p-calendar .p-inputtext:enabled:hover {
  border-color: #e1e4e8;
}

.loader {
  z-index: 1070 !important;
  top: 100px !important;
  float: left;
  width: 100% !important;
  height: 100% !important;
  justify-content: center;
  padding-top: 28%;
}

.loaderVessel {
  float: left;
  justify-content: center;
  padding: 4px;
  width: 25%;
}

.float {
  float: left;
  width: 100%;
}
.issue-description .remote-access {
  padding: none;
}

.approved {
  color: #2546b1;
  padding-right: 5px;
}

.no-record {
  position: relative;
  font-size: 13pt;
  font-family: "DAMEN-FONT";
  color: #4338ca;
  top: 40;
  height: 50%;
  width: 50%;
  top: 35%;
  left: 31%;
}

.info-icon {
  font-size: 17pt !important;
}

.blue {
  color: #4338ca;
  padding-bottom: 5px;
}

.email,
.current,
.next {
  float: left;
  width: 100%;
  text-align: left !important;
}

.current,
.next {
  padding-top: 10px;
}

@media only screen and (width: 820px) and (height: 1180px) {
  body {
    overflow-y: hidden !important; /* Hide vertical scrollbar */
    overflow-x: hidden !important; /* Hide horizontal scrollbar */
  }

  .no-record {
    position: relative;
    font-size: 13pt;
    font-family: "DAMEN-FONT";
    color: #4338ca;
    height: 50%;
    width: 50%;
    top: 40% !important;
    left: 40% !important;
  }

  .p-tabview-panels {
    height: 800px !important;
  }

  .p-tabview .p-tabview-nav li {
    padding-left: 167px !important;
  }

  .service-request-wrapper .header-wrapper {
    width: 100% !important;
  }

  .modal-content {
    width: 95% !important;
    height: 95% !important;
  }
  .detail-modal {
    .modal-dialog {
      // left: 25% !important;
    }
  }
}

@media only screen and (width: 768px) and (height: 1024px) {
  body {
    overflow-y: hidden !important; /* Hide vertical scrollbar */
    overflow-x: hidden !important; /* Hide horizontal scrollbar */
  }

  .no-record {
    position: relative;
    font-size: 13pt;
    font-family: "DAMEN-FONT";
    color: #4338ca;
    height: 50%;
    width: 50%;
    top: 40% !important;
    left: 40% !important;
  }

  .p-tabview-panels {
    height: 800px !important;
  }

  .p-tabview .p-tabview-nav li {
    padding-left: 167px !important;
  }

  .service-request-wrapper {
    width: 100%;
  }
  .modal-content {
    width: 95% !important;
    height: 95% !important;
  }
  .detail-modal {
    .modal-dialog {
      // left: 25% !important;
    }
  }
}

.error-msg {
  color: red;
  font-family: Arial;
  font-weight: 100;
}

.error-highlight {
  border: 1px solid red !important;
}

.float-customer{
  width:100%;
  float:left;
}

@media only screen and (orientation: landscape) {
  .loader {
    padding-top: 10% !important;
  }
  .service-request-wrapper {
    width: 101% !important;
    .header-wrapper {
      width: 100% !important;
      height: 68px !important;
      .app-header {
        height: 0% !important;
        padding-top: 0px !important;
      }
      .service-request-header {
        padding-top: 30px !important;
      }
    }
    .p-tabview-nav-content {
      height: 51px !important;
    }
    .p-tabview-panels {
      overflow: auto !important;
      // height: 200px !important;
      width: 99% !important;
    }
    .service-request-remote-access {
      height: 59px !important;
      width: 100% !important;
      background: white !important;
      border-top: 1px solid #8080802e !important;
      bottom: 3px !important;
      right: 0px !important;
      left: 0px !important;
      .card {
        border: none !important;
        margin-top: -4px !important;
        float: left !important;
        width: 60% !important;
      }
    }
    .p-paginator {
      padding: none !important;
    }
  }

  .modal-dialog .modal-content {
    overflow: auto !important;
    .modal-body {
      height: 1000px !important;
      overflow-y: auto;
      textarea {
        height: 100px !important;
        margin-bottom: 10px;
      }
    }
  }
  .detail-modal {
    .modal-dialog {
      // left: 20% !important;
    }
    .modal-content {
      height: 200px !important;
    }
  }
  .p-datepicker-group-container {
    // overflow: scroll !important;
    // height: 250px !important;
  }
}


@media only screen and (-webkit-min-device-pixel-ratio: 2.5) {
  .p-tabview-panels {
    overflow: auto !important;
    height: 470px !important;
    width: 100% !important;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
  .p-tabview-panels {
    overflow: auto !important;
    height: calc(100vh - 330px) !important;
    width: 100% !important;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 3.5) {
  .p-tabview-panels {
    overflow: auto !important;
    height: calc(100vh - 330px) !important;
    width: 100% !important;
  }
}

@media only screen and (width: 412px) and (height: 915px) {
  .p-tabview-panels {
    overflow: auto !important;
    height: 550px !important;
    width: 100% !important;
  }
}

@media only screen and (width: 414px) and (height: 896px) {
  .p-tabview-panels {
    overflow: auto !important;
    height: 550px !important;
    width: 100% !important;
  }
}

@media only screen and (width: 1180px) and (height: 820px) and (orientation: landscape) {
  .dashboard {
    overflow: hidden !important;
  }
  .modal-content {
    width: 99% !important;
  }
  .service-request-wrapper {
    .header-wrapper {
      width: 98% !important;
    }
    .p-tabview-panels {
      overflow: auto !important;
      height: 800px !important;
      width: 99% !important;
    }
    .service-request-remote-access {
      bottom: 11px !important;
    }
  }
  .detail-modal {
    .modal-dialog {
      // left: 30% !important;
    }
  }
}

@media all and (device-width: 1024px) and (device-height: 768px) and (orientation: landscape) {
  .dashboard {
    overflow: hidden !important;
  }
  .service-request-wrapper {
    //width: 101% !important;
    .header-wrapper {
      width: 99% !important;
    }
    .p-tabview-panels {
      overflow: auto !important;
      height: 800px !important;
      width: 99% !important;
    }
    .service-request-remote-access {
      bottom: 11px !important;
    }
  }
  .modal-content {
    width: 99% !important;
  }
  .detail-modal {
    .modal-dialog {
      // left: 30% !important;
    }
  }
}

@media all and (device-width: 375px) and (device-height: 667px) {
  .service-request-remote-access {
    bottom: 7px !important;
  }
  textarea {
    height: 101px !important;
  }

  .p-tabview-panel {
    height: 67% !important;
    width: 100% !important;
    overflow: auto !important;
  }
  .issue-description {
    font-size: 10pt !important;
  }
}

@media all and (device-width: 667px) and (device-height: 375px) and (orientation: landscape) {
  .detail-modal {
    .modal-dialog {
      // left: 15% !important;
    }
  }
}

@media all and (device-width: 360px) and (device-height: 740px) {
  .issue-description {
    font-size: 10pt !important;
    height: 22px !important;
  }
}

@media only screen and (min-width: 1000px) {
  .service-request-wrapper {
    .p-tabview-panels {
      overflow-y: auto !important;
      width: 99% !important;
      height: 75vh !important;
    }
    .service-request-remote-access {
      height: 63px !important;
    }
    .service-details-summary {
      cursor: default;
      .approved {
        cursor: pointer;
      }
    }
  }
  .detail-modal .modal-dialog {
    // left: 30% !important;
  }
  .modal-dialog .modal-content {
    overflow: hidden !important;
  }
}
.equipment-name{
  font-size: 14px;
  font-weight: 500;
  color: #2B8360;
  height: 25px;
  display: inline-block;
}