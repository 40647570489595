@font-face {
  font-family: "Damen-Font";
  src: local("Damen-Font"), url(./fonts/font.woff) format("woff");
}

body {
  margin: 0;
  font-family: Damen-font !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden !important;
}

.guard-wrapper {
  background-image: url("./assets/list-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.access-form-modal .modal-content .modal-body::-webkit-scrollbar-track,
.service-request-wrapper .p-tabview-panels::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: #f5f5f5;
  display: none;
}

.access-form-modal .modal-content .modal-body::-webkit-scrollbar,
.service-request-wrapper .p-tabview-panels::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
}

.access-form-modal .modal-content .modal-body::-webkit-scrollbar-thumb,
.service-request-wrapper .p-tabview-panels::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #c5c5c5;
}
/* code {
  font-family: Damen-font
    monospace;
} */

.toast-wrap .toast {
  position: absolute;
  right: 20px;
  width: 90%;
  bottom: 10px;
  background-color: #495057;
  color: #fff;
}

.toast-wrap .toast-header {
  background-color: #495057;
  color: #fff;
}

.unauthorized {
  text-align: center;
  background-color: #2546b1;
  color: #fff;
  height: 100vh;
  width: 100vw;
  background-position: bottom;
  background-repeat: no-repeat;
  background-position-x: left;
  float: left;
}

.center-align {
  position: fixed;
  top: 40%;
  left: 28%;
}
.link {
  text-decoration: underline;
  cursor: pointer;
  color: #fff;
  height: 100px;
  width: 100px;
  float: left;
  position: absolute;
  left: 40%;
}
.react-confirm-alert-overlay {
  background-color: rgb(175 167 167 / 90%) !important;
}
.react-confirm-alert-body {
  width: 350px !important;
  padding: 10px !important;
}
.react-confirm-alert-body h1 {
  font-weight: bold !important;
  color: #2546b1 !important;
}
.react-confirm-alert-button-group button {
  background-color: #2546b1 !important;
}
.react-confirm-alert-button-group {
  justify-content: center !important;
}
.modal-dialog {
  position: relative;
  width: 100% !important;
  padding: 0.5rem !important;
  margin: 0px !important;
  pointer-events: none;
  background: #a1accd;
  min-height: 100% !important;
  top: 0px !important;
}

.modal-body div {
  text-align: center;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2.5) {
  .center-align {
    top: 30% !important;
    left: 2% !important;
  }

  .link {
    left: 35% !important;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
  .center-align {
    top: 30% !important;
    left: 2% !important;
  }

  .link {
    left: 35% !important;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 3.5) {
  .center-align {
    top: 30% !important;
    left: 2% !important;
  }

  .link {
    left: 35% !important;
  }
}

@media only screen and (width: 375px) and (height: 667px) {
  .center-align {
    top: 30% !important;
    left: 2% !important;
  }

  .link {
    left: 35% !important;
  }
}

@media only screen and (width: 820px) and (height: 1180px) {
  .center-align {
    top: 30% !important;
    left: 15% !important;
  }

  .link {
    left: 35% !important;
  }
}

@media only screen and (width: 768px) and (height: 1024px) {
  .center-align {
    top: 30% !important;
    left: 10% !important;
  }

  .link {
    left: 35% !important;
  }
}

@media only screen and (width: 412px) and (height: 915px) {
  .center-align {
    top: 30% !important;
    left: 2% !important;
  }

  .link {
    left: 35% !important;
  }
}

@media only screen and (width: 414px) and (height: 896px) {
  .center-align {
    top: 30% !important;
    left: 2% !important;
  }

  .link {
    left: 35% !important;
  }
}

.p-datepicker {
  max-width: 420px !important;
}
