.bannerMessage{
    z-index: 1 !important;
    margin-bottom: 0px !important;
    position: absolute !important;
    bottom: 0px !important; 
    width: 100% !important;
}
.downtime{
    position: relative;
    right: -13px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  }
  .icon{
    float: left;
    left: 7px;
    position: absolute;
  }
  .info-icon {
    font-size: 12pt !important;
}
 .btn-close {
font-size: 0.7rem;
}