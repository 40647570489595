$sidebar-bg-color: #224691;
@import "react-pro-sidebar/dist/scss/styles.scss";
.pro-sidebar {
  position: absolute !important;
}
.menuIcon {
  font-size: 30px;
  color: #224691;
  position: absolute;
  right: 10px;
  cursor: pointer;
  z-index: 1007;
  // background-color: #efefef;
  margin-top: 10px;
  border-radius: 10px;
}
.backIcon {
  font-size: 30px;
  color: #224691;
  position: absolute;
  left: 5px;
  cursor: pointer;
  z-index: 1007;
  // background-color: #efefef;
  border-radius: 10px;
  margin-top: 10px;
}
.right-bar {
  right: 0 !important;
}
.modal-window {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  background-color: black;
  z-index: 1008;
}
.close-btn {
  float: right;
  font-size: 20px;
  margin-right: 10px;
  color: #fff;
  margin-top: 5px;
}
.menu {
  color: #ffffff;
  margin-top: 40%;
  margin-left: 25px;
  .icon {
    font-size: 30px;
  }
}
.head {
  padding-top: 50px;
  width: fit-content;
  height: 50%;
  margin: 0 auto;
  margin-bottom: 20px !important;
  .logo {
    float: left;
    margin-right: 7px;
    padding-top: 6px;
    width: 75pt !important;
  }
  .title {
    color: #fff;
    font-size: 25pt;
    text-align: left;
    display: inline-block;
  }
}



@media only screen and (-webkit-min-device-pixel-ratio: 4) {
  .menuIcon{
    right:10px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2) {
  .menuIcon{
    right:15px;
  }
}
