.service-form-wrapper {
  background: none;
  // height: 150px;
  width: 100%;
  height: 100vh;
  padding: 15px;
  .header-section {
    .backIcon {
      font-size: 30px;
      color: #224691;
      position: absolute;
      left: 19px;
      cursor: pointer;
      z-index: 1007;
      // background-color: #efefef;
      border-radius: 10px;
    }
    .app-header {
      text-align: center;
      color: #2546b1;
      font-size: 17pt;
      font-weight: bold;
    }
  }
  .title {
    padding: 0px;
    height: 100px;
  }

  .title .welcome {
    font-weight: bold;
    font-size: 13pt;
    color: black;
    height: 70%;
    width: 100%;
    float: left;
    padding: 10px;
    background: white;
    border-radius: 2px;
    border: 1px solid #e5e2e2;

    .user-box {
      float: left;
      font-size: 20pt;
      height: 100%;
      width: 60pt;
      text-align: center;
      padding-top: 2px;

      img {
        height: 50px;
        border-radius: 35px;
        width: 50px;
      }
    }
  }

  .title .request-header {
    float: left;
    height: 30px;
    width: 100%;
    text-align: center;
    font-weight: bold;
  }

  .issue-description-wrapper {
    font-family: arial;
    height: 250px;
    height: 270px;
    border-bottom: 1px solid #eeecec;

    .issue-container {
      width: 100%;
      margin: 0;

      .issue-description {
        width: 100%;
        text-align: left;
        padding-top: 10px;
        font-weight: bold;
        font-size: 13pt;
        float: left;
        width: 100%;
        height: 40px;
      }

      .part-specification {
        width: 100%;
        float: left;
        text-align: left;
        padding-top: 5px;
      }

      .issue-time-status {
        width: 100%;
        float: left;
        padding-top: 20px;
        font-size: 11pt;

        .issue-timestamp {
          width: 40%;
          float: left;
          text-align: left;
        }

        .issue-status-description-form {
          width: 58%;
          float: left;
          text-align: right;
        }
      }
      .pending {
        color: red;
      }

      .detailed-description {
        height: 140px;
        text-align: left;
        width: 100%;
        float: left;
        // padding-top: 15px;
        padding-bottom: 5px;

        .description-header {
          font-weight: bold;
        }

        .description-details {
          font-size: 10pt;
        }

        .description-images-complete {
          width: 100%;
          height: 50px;
          img {
            padding-right: 20px;
          }
        }
      }
    }
  }

  .workflow-wrapper {
    font-family: arial;
    float: left;
    width: 100%;
    margin-bottom: 25px;
    .workflow-header {
      padding-top: 10px;
      height: 30px;
      text-align: left;
      font-size: 13pt;
      font-weight: bold;
    }

    .workflow-steps-form {
      height: 90px;
      width: 100%;

      .step-summary-form {
        width: 100%;
        height: 100%;
        // margin-bottom: 10px;

        .worflow-diagram {
          height: 60px;
          float: left;
          width: 7%;
          padding-top: 10px;

          .workflow-round {
            height: 20px;
            width: 20px;
            border: 1px solid black;
            border-radius: 20px;
            .workflow-round-completed {
              height: 10px;
              width: 10px;
              border: 1px solid green;
              border-radius: 10px;
              background-color: green;
              margin-top: 4px;
              margin-left: 4px;
            }
          }

          .workflow-arrow {
            height: 43px;
            margin-left: 11px;
            margin-top: 7px;
            width: 100%;
            border-left: 1px solid black;
          }
        }

        .step-details {
          width: 92%;
          height: 30px;
          padding-top: 10px;
          float: left;

          .step-description {
            width: 50%;
            float: left;
            text-align: left;
            font-size: 10pt;
          }

          .step-timestamp {
            width: 50%;
            float: left;
            text-align: right;
            font-size: 10pt;
            color: #938e8e;
          }
        }

        .step-approval {
          width: 90%;
          height: 30px;
          font-size: 10pt;
          float: left;
          padding-top: 4px;

          .step-approver-details {
            width: 60%;
            float: left;
            text-align: left;
          }

          .step-status {
            width: 40%;
            float: left;
            text-align: right;
            padding-top: 5px;
            .pending-button {
              background-color: #ff7c52;
              color: white;
              border: none;
            }
          }

          .pending {
            color: red;
          }
        }
        .step-comment {
          width: 90%;
          height: 15px;
          float: left;
          padding-left: 21px;
          font-size: 10pt;
          font-style: italic;

          .step-comment-text {
          }
        }
      }

      .active {
        // margin-bottom: 10px;
        // margin-top: 10px;
        padding: 1px 5px;
        box-shadow: 1px;
        box-shadow: 1px 3px 7px grey;
        font-weight: bold;
      }
    }
  }
  .pending-steps-wrapper {
    width: 100%;
    font-family: arial;
    float: left;
    margin-top: 30px;
    color:grey;

    .task-header {
      font-size: 13pt;
      font-weight: bold;
      text-align: left;
      width: 100%;
      height: 30px;
    }

    .task-list-body {
      height: calc(100% - 10px);
      width: 100%;

      .task-list-details {
        float: left;
        width: 100%;
        padding-bottom: 10px;

        .workflow-round {
          height: 20px;
          width: 20px;
          border: 1px solid grey;
          border-radius: 20px;
          float: left;
        }

        .task-name {
          width: 80%;
          float: left;
          text-align: left;
          padding-left: 20px;
        }
      }
    }
  }
}

.modal-dialog {
  margin: 0 auto !important;
  background: none !important;
  top: 52%;
  z-index: 10;
  .modal-body{
    text-align: left;
  }
  .form-label {
    padding-top: 10px;
  }
  .field {
    z-index: 10;
    width: 100%;
  }
}

.report-form-modal {
  .modal-content {
    font-weight: bold;
    top: 25%;
    margin-left: -9px;
    height: 500px !important;
    position: fixed;
    border-radius: 0px;
    border: transparent;

    .modal-header{
      color :#2546B1;
    }

    .modal-body {
      padding-bottom: 0px;
      height: 200px;
      width: 100%;
      float: left;
      .request-estimate-header {
        width: 100%;
        float: left;
        text-align: center;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 12pt;
      }
      .work-report-title ,.work-report-description{
        color:#2546B1;
        text-align: left;
      }
    }
  }
}

.button-container {
  width: 100%;
  background: #e9ecef;
  left: 0px;
  border: 1px solid transparent !important;
  text-align: center;
  text-transform: none !important;
  padding: 5px;
  Button {
    background: transparent !important;
    border: transparent !important;
  }
  .button-text {
    text-transform: none !important;
    color: black !important;
  }
}

.submit-approval{
  background: #2546B1;
}
.p-dropdown-panel{
  z-index: 1070 !important;
  max-width: 445px !important;
}